.App {
  text-align: center;
}

h1 {
  text-transform: uppercase;
  margin-bottom: 0;
}

h2 {
  margin: 0.5em auto;
}

p {
  margin: 0 1em;
}

.App-logo {
  max-height: 40vmin;
  width: 30vh;
  pointer-events: none;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
}

.container > div {
  padding: 0 2em;
}

.winners {
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
  max-width: 3em;
  margin-bottom: 1em;
}

.counter {
  font-size: 10em;
  font-weight: bold;
  text-align: center;
  margin: 0.2em;
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
}

.result {
  min-width: 30vh;
  font-size: 3em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 0.2em;
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  line-height: 1;
}

.draw button.action {
  padding: 1em 2em;
  font-size: 2em;
  border-radius: 50%;
  box-shadow: 10px 10px rgba(10, 10, 10, 0.1);
  cursor: pointer;
  background-color: white;
}

.draw button.reset {
  padding: 1em 2em;
  font-size: 0.5em;
  border-radius: 50%;
  box-shadow: 10px 10px rgba(10, 10, 10, 0.1);
  cursor: pointer;
  background-color: white;
}

.draw button.action:hover {
  background-color: #41aee2;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('lottery-dark.jpg');
  padding-top: 3em;
}

.candidates textarea {
  width: 100%;
  font-size: 1.4em;
  min-height: 10em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
}

.App-link {
  color: #61dafb;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}